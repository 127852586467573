import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Divider from "./Divider";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Utils from "../utils/Utils";
import ScrollAnimation from "react-animate-on-scroll";
import { formatDate } from "../helpers";

function HomeInfografis() {
    const [datas, setDatas] = useState();
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");

    const getData = () => {
        fetch(`${Utils.urlDev}Infografis`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json.data);
                setDatas(json.data.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <section className="mt-5">
            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOut" animateOnce={true}>
                <Container>
                    <Row>
                        <Col>
                            <h3>Infografis</h3>
                            <Divider width={20} />
                        </Col>
                        <Col className="text-end">
                            <Button as={Link} to={`/${language[1]}/Infografis`} className="btn-theme hvr-float-shadow">Lihat Semua</Button>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-4 g-4 mb-3">
                        {
                            datas && (
                                datas.map((item, index) => (
                                    <Col key={index}>
                                        {
                                            index < 4 &&
                                            <Link className="text-decoration-none" to={`/${language[1]}/Infografis/Detail/${item.id}`}>
                                                <Card className="shadow" style={{ width: '100%', height: 400 }}>
                                                    <div style={{ overflow: 'hidden' }}>
                                                        <Card.Img className="image-hover-zoom" variant="top" src={`${Utils.urlStorageLocal}${item.photo_path}`} />
                                                    </div>
                                                    <Card.Body style={{ minHeight: '150px'}}>
                                                        <div className="w-100 text-start">
                                                            {item.judul}
                                                            <hr />
                                                            <div style={{ color: '#616161', fontSize: '10pt' }}>
                                                                {
                                                                    item.isi.length > 90 ? `${item.isi.substring(0, 90)}...` : item.isi
                                                                }
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <div className="w-100 text-start">
                                                            <small className="text-capitalize">
                                                                {formatDate(item.created_date)}
                                                            </small>
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            </Link>
                                        }
                                    </Col>
                                ))
                            )
                        }
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomeInfografis;