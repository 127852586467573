import { Card, Col, Container, Pagination, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { useEffect, useState } from "react";
import Utils from "../../utils/Utils";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import VideoGrafisSearch from "./VideoGrafisSearch";
import CardBeritaSkleton from "../../components/CardBeritaSkelton";

function VideoGrafisHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lang } = useParams();
    const [datas, setDatas] = useState([]);
    const [total, setTotal] = useState(0);
    const [totals, setTotals] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();

    const getData = () => {
        setTotal(0);
        setDatas([]);
        fetch(`${Utils.urlDev}VideoGrafis`)
            .then((response) => response.json())
            .then((json) => {
                setTimeout(() => {
                    setDatas(json.data.data)
                    setTotal(json.data.total)
                    setFrom(json.data.from)
                    setTo(json.data.last_page)
                    setActive(false)
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [searchParam.get("page")])

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        // console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <>
            <section style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="VideoGrafis" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <VideoGrafisSearch />
                        </Col>
                    </Row>

                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <Row className="row-cols-1 row-cols-md-4 g-4 mb-3">
                                {/* {
                                    datas && (
                                        datas.map((item, index) => (
                                            <Col key={index}>
                                                <CardItem datas={item} lang={lang} />
                                            </Col>
                                        ))
                                    )
                                } */}
                                {
                                    total === 0 ?
                                        totals.map((item, index) => (
                                            <Col key={index}>
                                                <CardBeritaSkleton />
                                            </Col>
                                        ))
                                        :
                                        datas.map((item, index) => (
                                            <Col key={index}>
                                                <CardItem datas={item} lang={lang} />
                                            </Col>
                                        ))
                                }
                            </Row>
                        </Col>
                    </Row>

                    {/* Pagination */}
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div>Total Item : {total}</div>
                            <div>Halaman ke {page} dari {to}</div>
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div className="d-flex justify-content-center">
                                <Paging
                                    handlePageChange={handlePageChanges}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

function CardItem({ datas, lang }) {
    return (
        <>
            <Link className="text-decoration-none" to={`/in/VideoGrafis/Detail/${datas.id}`}>
                <Card className="shadow" style={{ width: '100%' }}>
                    <div className="ratio ratio-16x9">
                        {/* <iframe src={`${Utils.urlStorageLocal}${item.video_path}?autoplay=0`} title={item.judul}></iframe> */}
                        <video controls>
                            <source src={`${Utils.urlStorageLocal}${datas.video_path}`} />
                        </video>
                    </div>
                    <Card.Body style={{ height: '150px' }}>
                        <Card.Text style={{ fontWeight: '600', color: '#464646' }}>
                            {datas.judul}
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small>{datas.created_date}</small>
                    </Card.Footer>
                </Card>
            </Link>
        </>
    )
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

export default VideoGrafisHome;