import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import ArtikelSearch from "./ArtikelSearch";
import CardDetailSkelton from "../../../components/CardDetailSkelton";

function ArtikelDetail() {
    const { docno } = useParams();
    const [datas, setDatas] = useState();

    const getData = () => {
        fetch(`${Utils.url}artikel/getArtikelId/${docno}`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);
                setDatas(json.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 2000);
    }, [])


    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Artikel Detail" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <ArtikelSearch />
                        </Col>
                    </Row>

                    {/* Peraturan Detail  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {
                                datas ? <Detail datas={datas} /> : <CardDetailSkelton />
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

function Detail({ datas }) {
    return (
        <Card className="shadow">
            <Card.Body className="h-100 text-start">
                <div style={{ fontWeight: '600', color: '#464646' }}>
                    {datas.subjek}
                </div>
                <hr />
                <div className="detail-judul">
                    <FontAwesomeIcon icon={faCopy} />
                    &nbsp; <span>Detail Artikel</span>
                </div>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Tipe Dokumen</td>
                            <td>{datas.tipe_dokumen_}</td>
                        </tr>
                        {/* <tr>
                            <td>Jenis Dokumen</td>
                            <td>{datas.jenis_dokumen}</td>
                        </tr> */}
                        <tr>
                            <td>Judul</td>
                            <td>{datas.judul}</td>
                        </tr>
                        <tr>
                            <td>T.E.U Badan</td>
                            <td>{datas.teu_badan}</td>
                        </tr>
                        <tr>
                            <td>Tempat Terbit</td>
                            <td>{datas.tempat_terbit}</td>
                        </tr>
                        <tr>
                            <td>Tahun Terbit</td>
                            <td>{datas.tahun_terbit}</td>
                        </tr>
                        {/* <tr>
                            <td>Penerbit</td>
                            <td>{datas.penerbit}</td>
                        </tr>
                        <tr>
                            <td>Tanggal Penetapan</td>
                            <td>-</td>
                        </tr> */}
                        <tr>
                            <td>Sumber</td>
                            <td>{datas.sumber}</td>
                        </tr>
                        <tr>
                            <td>Subjek</td>
                            <td>{datas.subjek}</td>
                        </tr>
                        <tr>
                            <td>Bahasa</td>
                            <td>{datas.bahasa_}</td>
                        </tr>
                        <tr>
                            <td>Bidang Hukum</td>
                            <td>{datas.bidang_hukum_}</td>
                        </tr>
                        <tr>
                            <td>Lokasi</td>
                            <td>{datas.lokasi}</td>
                        </tr>
                        <tr>
                            <td>Lampiran</td>
                            <td>
                                <Link to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank">{datas.lampiran_name}</Link>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                    <Button as={Link} to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank" variant="info" className="text-white">
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;
                        Unduh
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ArtikelDetail;