const Utils = {
    url: "https://api-jdih.bnpp.go.id/",
    // url: "http://127.0.0.1:8081/",
    urlStorage: "https://admin-jdih.bnpp.go.id/",
    token: "", 
    // urlDev: "http://116.0.4.61:8083/api/",
    // urlStorageLocal: "http://116.0.4.61:8083/"
    // urlDev: "http://localhost:8000/api/",
    urlDev: "https://admin-jdih.bnpp.go.id/api/",
    urlStorageLocal: "https://admin-jdih.bnpp.go.id/"
    // urlDev: "127.0.0.1:8000/api/",
}

export default Utils;