export function formatDate(date) {
    const tanggal = new Date();
    const tahun = tanggal.getFullYear();
    const bulan = tanggal.getMonth() + 1; // bulan dimulai dari 0
    const hari = tanggal.getDate();
    const result = `${hari} ${formatMonth(bulan)} ${tahun}`

    return result;
}

export function formatMonth(month) {
    const arr = [
        "januari",
        "februari",
        "maret",
        "april",
        "mei",
        "juni",
        "juli",
        "agustus",
        "september",
        "oktober",
        "nopember",
        "desember",
    ];

    return arr[Number(month) - 1];
}