import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Divider from "./Divider";
import ScrollAnimation from "react-animate-on-scroll";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Utils from "../utils/Utils";
import { formatDate } from "../helpers";

function HomeVideoGrafis() {
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");
    const [datas, setDatas] = useState([]);

    const getData = () => {
        fetch(`${Utils.urlDev}VideoGrafis`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json.data);
                setDatas(json.data.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <section className="mt-5 pt-5 pb-5" style={{ background: '#dedede' }}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Container>
                    <Row>
                        <Col>
                            <h3>Video Grafis</h3>
                            <Divider width={20} />
                        </Col>
                        <Col className="text-end">
                            <Button as={Link} to={`/${language[1]}/VideoGrafis`} className="btn-theme hvr-float-shadow">Lihat Semua</Button>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-3 g-4 mb-3">
                        {datas.map((item, index) => (
                            <Col key={index}>
                                {
                                    index < 3 &&
                                    <Link className="text-decoration-none" to={`/${language[1]}/VideoGrafis/Detail/${item.id}`}>
                                        <Card className="shadow" style={{ width: '100%' }}>
                                            <div className="ratio ratio-16x9">
                                                {/* {
                                                    index === 0 ?
                                                        <video controls autoPlay>
                                                            <source src={`${Utils.urlStorageLocal}${item.video_path}`} />
                                                        </video>
                                                        :
                                                        <video controls>
                                                            <source src={`${Utils.urlStorageLocal}${item.video_path}`} />
                                                        </video>
                                                } */}
                                                <video controls>
                                                    <source src={`${Utils.urlStorageLocal}${item.video_path}`} />
                                                </video>
                                            </div>
                                            <Card.Body style={{ height: '150px' }}>
                                                <Card.Text style={{ fontWeight: '600', color: '#464646' }}>
                                                    {item.judul}
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <small className="text-capitalize">{formatDate(item.created_date)}</small>
                                            </Card.Footer>
                                        </Card>
                                    </Link>
                                }
                            </Col>
                        ))}
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomeVideoGrafis;