import { Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Divider from "../../components/Divider";
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from "react";

const SearchingAi = () => {

    const { keyword } = useParams();
    const [key, setKey] = useState('home');

    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Ringkasa AI" />
            <Container>
                <Row className="mt-5 pb-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div className="bg-light p-3">
                            <Row>
                                <div className="p-3">
                                    <div className="w-100 d-flex justify-content-start">
                                        <FontAwesomeIcon icon={faStar} className="fa-lg mt-1" />
                                        &nbsp;
                                        &nbsp;
                                        <h3 className="d-inline">Ringkasan AI</h3>
                                    </div>
                                    <Divider width={20} />
                                </div>
                            </Row>
                            <Row>
                                <div className="p-3">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="Semua" id="tabhome">
                                            <div className="text-start px-2">
                                                Badan Nasional Pengelola Perbatasan (disingkat BNPP) adalah Badan Pengelola Batas Wilayah Negara dan Kawasan Perbatasan sebagaimana dimaksud Undang-Undang Nomor 43 Tahun 2008 tentang Wilayah Negara. BNPP merupakan lembaga nonstruktural yang dipimpin oleh seorang Kepala Badan yang berkedudukan di bawah dan bertanggung jawab kepada Presiden..
                                            </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="Peraturan">
                                            Pencarian tidak di temukan
                                        </Tab>
                                        <Tab eventKey="berita" title="Berita">
                                            Pencarian tidak di temukan
                                        </Tab>
                                        <Tab eventKey="infografis" title="Infografis">
                                            Pencarian tidak di temukan
                                        </Tab>
                                        <Tab eventKey="lainnya" title="Lainya">
                                            Pencarian tidak di temukan
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SearchingAi;