import { Card, Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import Utils from "../../../utils/Utils";
import { connect } from "react-redux";
import Divider from "../../../components/Divider";

function VisiMisi(props) {

    // console.log(props);

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Visi Misi" />
                <Container>
                    {/* <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-start">
                            <div style={{ fontWeight: '600', color: '#464646' }}>
                                JDIH Badan Nasional Pengelola Perbatan RI
                            </div>
                            <Divider width={20} />
                        </Col>
                    </Row> */}
                    <Row className="align-item-center justify-content-center h-100 mt-5">
                        <Col lg={10} className="text-center">
                            {/* <object data={`${Utils.urlStorage}${props.visiMisi}`}
                                style={{ width: '100%', height: '700px' }}>
                            </object> */}
                            <Card>
                                <Card.Body className="text-start">
                                    <div style={{ fontWeight: '600', color: '#464646' }}>
                                        JDIH Badan Nasional Pengelola Perbatan RI
                                    </div>
                                    <Divider width={20} />
                                    {props.visiMisi && (
                                        // <div>{props.visiMisi}</div>
                                        <div dangerouslySetInnerHTML={{ __html: props.visiMisi }} />
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        visiMisi: state.visiMisi
    }
}

export default connect(mapStateToProps)(VisiMisi);