import { Button, Card, Col, Container, Pagination, Row } from "react-bootstrap";
import ArtikelSearch from "./ArtikelSearch";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Utils from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import CardItemSkelton from "../../../components/CardItemSkelton";

function ArtikelHome() {
    const [datas, setDatas] = useState();
    const [total, setTotal] = useState(0);
    const { lang } = useParams();
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();
    const navigate = useNavigate();
    const [totalSkelton, setTotalSkelton] = useState([1, 2, 3, 4, 5, 6, 7]);

    const handleNavigate = (val) => {
        navigate(`/id/Artikel/Detail/${val}`)
    }

    const getData = () => {
        setDatas([])
        setTotal(0)
        fetch(`${Utils.url}artikel/getArtikel?page=${page}`)
            .then((response) => response.json())
            .then((json) => {
                setTimeout(() => {
                    setDatas(json.data.data);
                    setTotal(json.data.total);
                    setFrom(json.data.from);
                    setTo(json.data.last_page);
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [searchParam.get("page")]);

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        // console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <>
            <section style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Artikel" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <ArtikelSearch />
                        </Col>
                    </Row>

                    {/* card item  */}
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {/* {datas && (
                                datas.map((item, index) => (
                                    <div key={index} className="mb-4">
                                        <CardItem dataArtikel={item} onNavigate={handleNavigate} />
                                    </div>
                                ))
                            )} */}
                            {
                                total === 0 ?
                                    totalSkelton.map((item, index) => (
                                        <CardItemSkelton key={index} />
                                    ))
                                    :
                                    datas.map((item, index) => (
                                        <div key={index} className="mb-4">
                                            <CardItem dataArtikel={item} onNavigate={handleNavigate} />
                                        </div>
                                    ))
                            }
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div>Total Item : {total}</div>
                            <div>Halaman ke {page} dari {to}</div>
                        </Col>
                    </Row>

                    {/* Pagination */}
                    <Row className="mt-1 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div className="d-flex justify-content-center">
                                <Paging
                                    handlePageChange={handlePageChanges}
                                />
                            </div>
                        </Col>
                    </Row>


                </Container>
            </section>
        </>
    );
}


function CardItem({ dataArtikel, onNavigate }) {

    const onNav = (e, val) => {
        e.preventDefault()
        onNavigate(val)
    }

    return (
        <Link className="text-decoration-none hvr-float-shadow w-100" onClick={(e) => onNav(e, dataArtikel.request_no)}>
            <Card className="shadow-sm w-100">
                <Card.Body className="h-100 text-start">
                    <div className="d-flex bd-highlight mb-3 mt-3">
                        <div className="pe-3 bd-highlight widget-head">
                            <div className="text-center rounded widget">
                                <div style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faFilePdf} style={{ color: '#e6e6e6', fontSize: '20pt' }} />
                                </div>
                            </div>
                        </div>
                        <div className="bd-highlight">
                            <div style={{ fontWeight: '600' }}>{dataArtikel.sumber}</div>
                            <div className="mb-3" style={{ color: '#5c5c5c' }}>{dataArtikel.judul}</div>
                            <div style={{ color: '#5c5c5c' }}>{dataArtikel.tanggal}</div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="h-100 text-start">
                            <div className="d-flex bd-highlight">
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faDownload} />
                                        &nbsp;
                                        {dataArtikel.id}
                                    </Button>
                                </div>
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faEye} />
                                        &nbsp;
                                        {dataArtikel.view}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col className="h-100 text-end">
                            <Button variant="info" className="btn-sm text-white">
                                <FontAwesomeIcon icon={faDownload} />
                                &nbsp;
                                Unduh
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Link>
    );
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

export default ArtikelHome;