import { Card, Col, Container, Pagination, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import InfografisSearch from "./InfografisSearch";
import { useEffect, useState } from "react";
import Utils from "../../utils/Utils";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CardBeritaSkelton from "../../components/CardBeritaSkelton";
import { Divider } from "@mui/material";

function InfografisHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [datas, setDatas] = useState([]);
    const [total, setTotal] = useState(0);
    const [totals, setTotals] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();

    const getData = () => {
        setTotal(0);
        setDatas([]);
        fetch(`${Utils.urlDev}Infografis`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json.data);
                setTimeout(() => {
                    setDatas(json.data.data)
                    setTotal(json.data.total)
                    setFrom(json.data.from)
                    setTo(json.data.last_page)
                    setActive(false)
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [searchParam.get("page")])

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        // console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <>
            <section style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Infografis" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <InfografisSearch />
                        </Col>
                    </Row>

                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <Row className="row-cols-1 row-cols-md-4 g-4 mb-3">
                                {
                                    total === 0 ?
                                        totals.map((item, index) => (
                                            <Col key={index}>
                                                <CardBeritaSkelton />
                                            </Col>
                                        ))
                                        :
                                        datas.map((item, index) => (
                                            <Col key={index}>
                                                <Detail item={item} />
                                            </Col>
                                        ))

                                }
                            </Row>
                        </Col>
                    </Row>

                    {/* Pagination */}
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div>Total Item : {total}</div>
                            <div>Halaman ke {page} dari {to}</div>
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div className="d-flex justify-content-center">
                                <Paging
                                    handlePageChange={handlePageChanges}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

function Paging({ handlePageChange }) {
    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

function Detail({ item }) {
    return (
        <Link className="text-decoration-none" to={`/in/Infografis/Detail/${item.id}`}>
            <Card className="shadow" style={{ width: '100%' }}>
                <div style={{ overflow: 'hidden', height: 250 }}>
                    <Card.Img className="image-hover-zoom" variant="top" src={`${Utils.urlStorageLocal}${item.photo_path}`} />
                </div>
                <Card.Body style={{ minHeight: '130px'}}>
                    <div className="w-100 text-start">
                        {item.judul}
                        <hr />
                        <div style={{ color: '#616161', fontSize: '10pt' }}>
                            {
                                item.isi.length > 90 ? `${item.isi.substring(0, 90)}...` : item.isi
                            }
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="w-100 text-start">
                        <small>
                            {item.created_date}
                        </small>
                    </div>
                </Card.Footer>
            </Card>
        </Link>
    );
}

export default InfografisHome;