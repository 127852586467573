import { Button, Card, Col, Container, Image, Pagination, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import MonografiSearch from "./MonografiSearch";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Utils from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import CardItemSkelton from "../../../components/CardItemSkelton";

function MonografiHome() {
    const [datas, setDatas] = useState([]);
    const [total, setTotal] = useState(0);
    const { lang } = useParams();
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();
    const navigate = useNavigate();
    const [totalSkelton, setTotalSkelton] = useState([1, 2, 3, 4, 5, 6, 7]);

    const handleNavigate = (val) => {
        navigate(`/id/Monografi/Detail/${val}`)
    }

    const getData = () => {
        setDatas([])
        setTotal(0)
        fetch(`${Utils.url}monografi/getMonografi?page=${page}`)
            .then((response) => response.json())
            .then((json) => {
                setTimeout(() => {
                    setDatas(json.data.data);
                    setTotal(json.data.total);
                    setFrom(json.data.from);
                    setTo(json.data.last_page);
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [searchParam.get("page")]);

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        // console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <>
            <section style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Monografi" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <MonografiSearch />
                        </Col>
                    </Row>

                    {/* card item  */}
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {
                                total === 0 ?
                                    totalSkelton.map((item, index) => (
                                        <CardItemSkelton key={index} />
                                    ))
                                    :
                                    datas.map((item, index) => (
                                        <div key={index} className="mb-4">
                                            <CardItem dataMonografi={item} onNavigate={handleNavigate} />
                                        </div>
                                    ))
                            }
                            {/* {datas && (
                                datas.map((item, index) => (
                                    <div key={index} className="mb-4">
                                        <CardItem dataMonografi={item} onNavigate={handleNavigate} />
                                    </div>
                                ))
                            )} */}
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div>Total Item : {total}</div>
                            <div>Halaman ke {page} dari {to}</div>
                        </Col>
                    </Row>

                    {/* Pagination */}
                    <Row className="mt-1 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div className="d-flex justify-content-center">
                                <Paging
                                    handlePageChange={handlePageChanges}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

function CardItem({ dataMonografi, onNavigate }) {

    const onNav = (e, val) => {
        e.preventDefault()
        onNavigate(val)
    }

    return (
        <Link className="text-decoration-none hvr-float-shadow w-100" onClick={(e) => onNav(e, dataMonografi.request_no)}>
            <Card className="shadow-sm w-100">
                <Card.Body className="h-100 text-start">
                    <div className="d-flex bd-highlight mb-3 mt-3">
                        <div className="pe-3 bd-highlight">
                            <div className="shadow rounded" style={{ position: 'relative', height: '100px', width: '100px' }}>
                                <Image
                                    className="rounded"
                                    src={`${Utils.urlStorage}/${dataMonografi.gambar_sampul_path}`}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="bd-highlight">
                            <div className="mb-2" style={{ fontWeight: '600' }}>{dataMonografi.subjek}</div>
                            <div style={{ color: '#5c5c5c' }}>
                                {dataMonografi.judul}
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="h-100 text-start">
                            <div className="d-flex bd-highlight">
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faDownload} />
                                        &nbsp;
                                        {dataMonografi.id}
                                    </Button>
                                </div>
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faEye} />
                                        &nbsp;
                                        {dataMonografi.view}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col className="h-100 text-end">
                            <Button variant="info" className="btn-sm text-white">
                                <FontAwesomeIcon icon={faDownload} />
                                &nbsp;
                                Unduh
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Link>
    );
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

export default MonografiHome;