import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Utils from "../../../utils/Utils";
import CardDetailSkelton from "../../../components/CardDetailSkelton";
import PutusanSearch from "./PutusanSearch";

function PutusanDetail(props) {
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");
    const { docno } = useParams();
    const [datas, setDatas] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getData = () => {
        setDatas([]);
        fetch(`${Utils.url}putusan/getPutusanId/${docno}`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);
                setDatas(json.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSearch = (data) => {
        dispatch({
            type: "SET_SEARCH",
            data: data
        });
    }

    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 2000);
    }, [])

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Putusan Detail" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <PutusanSearch handleSearch={handleSearch} />
                        </Col>
                    </Row>

                    {/* Putusan Detail  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {
                                datas ? <Detail datas={datas} language={language[1]} /> : <CardDetailSkelton />
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

function Detail({ datas }) {
    return (
        <Card className="shadow">
            <Card.Body className="h-100 text-start">
                <a href="#" className="btn btn-outline-info">{datas.tipe_dokumen_}</a>
                <hr />
                <div className="detail-judul">
                    <FontAwesomeIcon icon={faCopy} />
                    &nbsp; <span>Detail Putusan</span>
                </div>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Tipe Dokumen</td>
                            <td>{datas.tipe_dokumen_}</td>
                        </tr>
                        <tr>
                            <td>Judul</td>
                            <td>{datas.judul}</td>
                        </tr>
                        <tr>
                            <td>T.E.U Badan</td>
                            <td>{datas.teu_badan}</td>
                        </tr>
                        <tr>
                            <td>Nomor Putusan</td>
                            <td>{datas.nomor}</td>
                        </tr>
                        <tr>
                            <td>Jenis Peradilan</td>
                            <td>{datas.jenis_peradilan_}</td>
                        </tr>
                        <tr>
                            <td>Singkatan Jenis Peradilan</td>
                            <td>{datas.singkatan_jenis}</td>
                        </tr>
                        <tr>
                            <td>Tempat Peradilan</td>
                            <td>{datas.tempat_peradilan}</td>
                        </tr>
                        <tr>
                            <td>Tanggal-Bulan-Tahun-dibacakan</td>
                            <td>{datas.tanggal_dibacakan_}</td>
                        </tr>
                        <tr>
                            <td>Sumber</td>
                            <td>{datas.sumber}</td>
                        </tr>
                        <tr>
                            <td>Subjek</td>
                            <td>{datas.subjek}</td>
                        </tr>
                        <tr>
                            <td>Status Putusan</td>
                            <td>{datas.status_putusan_}</td>
                        </tr>
                        <tr>
                            <td>Bahasa</td>
                            <td>{datas.bahasa_}</td>
                        </tr>
                        <tr>
                            <td>Bidang Hukum / Jenis Perkara</td>
                            <td>{datas.bidang_hukum_}</td>
                        </tr>
                        <tr>
                            <td>Lokasi</td>
                            <td>{datas.lokasi}</td>
                        </tr>
                        <tr>
                            <td>Lampiran</td>
                            <td>
                                <Link to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank">{datas.lampiran_name}</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Jumlah Unduh</td>
                            <td>0 kali unduh</td>
                        </tr>
                        <tr>
                            <td>Jumlah Tayang</td>
                            <td>{datas.view} kali tayang</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                    <Button as={Link} to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank" variant="info" className="text-white">
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;
                        Unduh
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default PutusanDetail;