import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Divider from "./Divider"
import Utils from "../utils/Utils";
import { Link, useParams } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { formatDate } from "../helpers";

function HomeBerita({ dataBerita }) {
    const { lang } = useParams();

    return (
        <section className="mt-5 pt-5 pb-5" style={{ background: '#dedede' }}>
            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOut" animateOnce={true}>
                <Container>
                    <Row>
                        <Col>
                            <h3>Berita Terbaru</h3>
                            <Divider width={20} />
                        </Col>
                        <Col className="text-end">
                            <Button className="btn-theme hvr-float-shadow" as={Link} to={`/${lang}/Berita`}>Lihat Semua</Button>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-4 g-4 mb-3">
                        {dataBerita.slice(0, 4).map((item, index) => (
                            <Col key={index}>
                                <Card className="shadow" style={{ width: '100%', fontSize: '10pt' }}>
                                    <div style={{ height: '150px', width: '100%', position: 'relative' }}>
                                        <Card.Img
                                            variant="top"
                                            src={`${Utils.urlStorage}/${item.gambar_path}`}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                position: 'absolute',
                                                objectFit: 'cover',
                                                left: '0'
                                            }}
                                        />
                                    </div>
                                    <Card.Body style={{ height: '120px' }}>
                                        <Card.Text style={{ fontWeight: '600', color: '#464646' }}>
                                            {item.judul}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row>
                                            <Col lg={8}>
                                                <div className="pt-2 text-capitalize">
                                                    {formatDate(item.created_date)}
                                                </div>
                                            </Col>
                                            <Col className="text-end">
                                                <Button as={Link} to={`/${lang}/Berita/Detail/${item.judul_}`} variant="outline-info" style={{ fontSize: '10pt' }}>Detail</Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomeBerita;