import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import PeraturanSearch from "./PeraturanSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Utils from "../../../utils/Utils";
import CardDetailSkelton from "../../../components/CardDetailSkelton";

function PeraturanDetail(props) {
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");
    const { docno } = useParams();
    const [datas, setDatas] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getData = () => {
        setDatas([]);
        fetch(`${Utils.url}peraturan/getPeraturanId/${docno}`)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);
                setDatas(json.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSearch = (data) => {
        dispatch({
            type: "SET_SEARCH",
            data: data
        });
        navigate(`/in/Peraturan/Search`)
    }

    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 2000);
    }, [])
    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Peraturan Detail" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <PeraturanSearch
                                handleSearch={handleSearch}
                                jenisPer={props.jenisPeraturan}
                                singkatanJen={props.singkatanJenis}
                                statusPer={props.statusPeraturan}
                            />
                        </Col>
                    </Row>

                    {/* Peraturan Detail  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {
                                datas ? <Detail datas={datas} language={language[1]} /> : <CardDetailSkelton />
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

function Detail({ datas, language }) {
    return (
        <Card className="shadow">
            <Card.Body className="h-100 text-start">
                <a href="#" className="btn btn-outline-info">{datas.singkatan_jenis}</a>
                <hr />
                <div className="detail-judul">
                    <FontAwesomeIcon icon={faCopy} />
                    &nbsp; <span>Detail Peraturan</span>
                </div>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Tipe Dokumen</td>
                            <td>{datas.tipe_dokumen_}</td>
                        </tr>
                        <tr>
                            <td>Jenis Peraturan</td>
                            <td>{datas.jenis_peraturan_}</td>
                        </tr>
                        <tr>
                            <td>Judul</td>
                            <td>{datas.judul}</td>
                            {/* <td>Peraturan BNPP Nomor {datas.nomor} Tahun {datas.tahun_terbit}</td> */}
                        </tr>
                        <tr>
                            <td>T.E.U Badan</td>
                            <td>{datas.teu_badan}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '27%' }}>Nomor Peraturan</td>
                            <td>{datas.nomor}</td>
                        </tr>
                        <tr>
                            <td>Jenis / Bentuk Peraturan</td>
                            <td>{datas.jenis_peraturan_}</td>
                        </tr>
                        <tr>
                            <td>Singkatan Jenis / Bentuk Peraturan</td>
                            <td>{datas.singkatan_jenis}</td>
                        </tr>
                        <tr>
                            <td>Tempat Penetapan</td>
                            <td>{datas.tempat_penetapan}</td>
                        </tr>
                        <tr>
                            <td>Tanggal-Bulan-Tahun-Penetapan / Pengundangan</td>
                            <td>{datas.tanggal_penetapan_}</td>
                        </tr>
                        {/* <tr>
                            <td>Tanggal Pengundangan</td>
                            <td>{datas.tanggal_pengundangan_}</td>
                        </tr> */}
                        <tr>
                            <td>Sumber</td>
                            <td>{datas.sumber}</td>
                        </tr>
                        <tr>
                            <td>Subjek</td>
                            <td>{datas.subjek}</td>
                        </tr>
                        <tr>
                            <td>Status Peraturan</td>
                            <td>{datas.status_peraturan_}</td>
                        </tr>
                        <tr>
                            <td>Bahasa</td>
                            <td>{datas.bahasa_}</td>
                        </tr>
                        <tr>
                            <td>Lokasi</td>
                            <td>{datas.lokasi}</td>
                        </tr>
                        <tr>
                            <td>Bidang Hukum</td>
                            <td>{datas.bidang_hukum_}</td>
                        </tr>
                        {/* <tr>
                            <td>Penanda tangan</td>
                            <td>{datas.penandatanganan}</td>
                        </tr> */}
                        <tr>
                            <td>Lampiran</td>
                            <td>
                                {/* <Link to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank">{datas.judul}</Link> */}
                                <Link to={`${Utils.urlStorage}${datas.lampiran_path}`} target="_blank">{datas.lampiran_name}</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Abstrak</td>
                            <td>
                                {/* <Link to={`${Utils.urlStorage}${datas.abstrak_path}`} target="_blank">{datas.judul}</Link> */}
                                <Link to={`${Utils.urlStorage}${datas.abstrak_path}`} target="_blank">{datas.abstrak_name}</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Peraturan Terkait</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Jumlah Unduh</td>
                            <td>{datas.id} kali di unduh</td>
                        </tr>
                        <tr>
                            <td>Jumlah Tayang</td>
                            <td>{datas.view} kali tayang</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                    <Button
                        as={Link}
                        to={`/${language}/Infografis/Detail/2`}
                        variant="info"
                        className="text-white"
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;
                        Infografis
                    </Button>
                    &nbsp;
                    <Button
                        as={Link}
                        to={`${Utils.urlStorage}${datas.lampiran_path}`}
                        target="_blank"
                        variant="info" className="text-white"
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;
                        Unduh
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
        jenisPeraturan: state.jenisPeraturan,
        singkatanJenis: state.singkatanJenis,
        statusPeraturan: state.statusPeraturan
    }
}

export default connect(mapStateToProps)(PeraturanDetail);