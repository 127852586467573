import { Badge, Card, Col, Container, Form, ListGroup, Nav, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { Bar } from "react-chartjs-2";
import { Divider } from "@mui/material";

const Statistic = () => {

    const OPTIONS = {
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
    }

    const PERATURAN = {
        labels: ['Peraturan Badan', 'Peraturan Kepala Badan', 'Keputusan Presiden', 'Keputusan Presiden'],
        datasets: [
            {
                label: "Peraturan",
                data: [26, 12, 15, 20],
            },
        ]
    }

    return (
        <section className="pb-5" style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Statistik" />
            <Container>
                <Row className="mt-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <Card>
                            <Card.Body className="text-start">
                                <Row>
                                    <div className="p-5">
                                        <label>Lihat Grafik</label>
                                        <Form.Select id="select-meta">
                                            <option>-- Pilih Grafik --</option>
                                            <option selected>Peraturan</option>
                                            <option>Monografi</option>
                                            <option>Artikel</option>
                                            <option>Putusan</option>
                                        </Form.Select>
                                    </div>
                                </Row>
                                <Row className="px-4 ms-1">
                                    <Col lg={6}>
                                        <Card>
                                            <Card.Header>
                                                Deskripsi
                                            </Card.Header>
                                            <Card.Body>
                                                <Nav defaultActiveKey="/home" className="flex-column">
                                                    <Nav.Item className="py-2 border-bottom">
                                                        Peraturan Badan
                                                        &nbsp;
                                                        <Badge bg="success">9</Badge>
                                                    </Nav.Item>
                                                    <Nav.Item className="py-2 border-bottom">
                                                        Peraturan Kepala Badan
                                                        &nbsp;
                                                        <Badge bg="success">20</Badge>
                                                    </Nav.Item>
                                                    <Nav.Item className="py-2 border-bottom">
                                                        Keputusan Presiden
                                                        &nbsp;
                                                        <Badge bg="success">17</Badge>
                                                    </Nav.Item>
                                                    <Nav.Item className="py-2">
                                                        Peraturan Walikota
                                                        &nbsp;
                                                        <Badge bg="success">26</Badge>
                                                    </Nav.Item>
                                                </Nav>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Bar data={PERATURAN} options={OPTIONS} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Statistic;