import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PutusanSearch() {
    return (
        <div className="bg-dark-app pt-3 rounded shadow">
            <Form style={{ padding: '0 15px 0 15px' }}>
                {/* <Form.Control type="email" placeholder="Search" /> */}
                <Row>
                    {/* <Col lg={2}>
                        <label className="text-white w-100 text-start">Pilih Tahun</label>
                        <Form.Select
                            className="input-search"
                            placeholder="Search"
                            aria-describedby="basic-addon2"
                        >
                            <option>-- Pilih Tahun --</option>
                        </Form.Select>
                    </Col> */}
                    <Col>
                        <label className="text-white w-100 text-start">Masukan kata kunci pencarian putusan</label>
                        <InputGroup style={{ paddingBottom: '15px' }}>
                            <Form.Control
                                className="input-search"
                                placeholder="Search"
                                aria-describedby="basic-addon2"
                            />
                            <Button className="btn-search" variant="outline-secondary" id="button-addon2">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default PutusanSearch;